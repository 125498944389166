import "iframe-resizer";
import { HelmetProvider } from "@dogstrust/dt-web-core-lib-front-end/src/Helmet";
import { CmsMapProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/CmsMap/CmsMap.provider";
import { DogSearchApiProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/DogSearchApi/ie/DogSearchApi.provider";
import FavouritesManagerProvider from "@dogstrust/dt-web-core-lib-front-end/src/context/FavouritesManager/FavouritesManager.provider";
import { MyAccountProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/MyAccount/ie/MyAccount.provider";
import { PageManagerProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/PageManager/PageManager.provider";
import React from "react";
import type { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "@dogstrust/dt-web-core-lib-front-end/src/style/theme.scss";
import { Fragments } from "@dogstrust/ie/gql";

interface Props {
	element: ReactNode;
}

const RootElement: React.FC<Props> = ({ element }) => (
	<GoogleReCaptchaProvider
		reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
		useRecaptchaNet={true}
		useEnterprise={true}
		scriptProps={{
			async: false,
			defer: false,
			appendTo: "head",
		}}
	>
		<Fragments />
		<PageManagerProvider>
			<MyAccountProvider>
				<HelmetProvider>
					<CmsMapProvider>
						<DogSearchApiProvider>
							<FavouritesManagerProvider>{element}</FavouritesManagerProvider>
						</DogSearchApiProvider>
					</CmsMapProvider>
				</HelmetProvider>
			</MyAccountProvider>
		</PageManagerProvider>
	</GoogleReCaptchaProvider>
);

export const wrapRootElement = RootElement;
